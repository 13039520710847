import React, { useState } from 'react'
import { ReactComponent as Img } from '../assets/img/yin_yang.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { EditOrderItemModal } from './EditOrderItemModal'
import { Checkbox } from './Checkbox'
import { OrderItem, File as DepotoFile, ProductBundle, ProductMove, Product } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { useCore } from '../hooks'
import { PhotoSwipe } from './PhotoSwipe'
import { FormInput } from './FormInput'
// import { EditReturnItemModal } from './EditReturnItemModal'
import { DateFormatted } from './DateFormatted'
import { Dropdown } from './Dropdown'

type Props = {
  isBundleChild?: boolean
  isReturns?: boolean
  returnedMoves?: any
  movesToReturn?: Array<{
    amount: number
    batch: string
    expirationDate: string
    position1: string
    position2: string
    position3: string
    orderItemId: string | number
    productId: string | number
  }>
  orderItem: OrderItem
  isInPackage?: boolean
  currentPackage?: any
  packages?: any[]
  onUpdate: Fn
  onReturn: Fn
}

export const OrderItemRow: React.FC<Props> = ({
  isBundleChild,
  orderItem,
  isReturns,
  returnedMoves,
  movesToReturn,
  isInPackage,
  currentPackage,
  packages = [],
  onUpdate,
  onReturn,
}) => {
  // const [amountForReturn, setAmountForReturn] = useState<number>(0) // todo?
  const { core, isFetching, setIsFetching, currentOrder } = useCore()

  const thumbUrl = orderItem.product?.mainImage?.thumbnails?.length
    ? orderItem.product?.mainImage.thumbnails.filter(t => t.format === 'w135')[0].url
    : ''

  const imageUrl = orderItem.product?.mainImage?.thumbnails?.length
    ? orderItem.product?.mainImage.thumbnails.filter(t => t.format === 'w2048')[0].url
    : ''

  const processStatus = currentOrder?.processStatus?.id

  const setPicked = async (picked: boolean) => {
    // if (currentOrder.isLocked) { // todo
    //   return
    // }
    setIsFetching(true)
    try {
      await core?.services.order.updateOrderItemPart({ id: orderItem.id, picked })
    } catch (errors) {
      alert(`Chyba:\n${JSON.stringify(errors)}`)
    }
    setIsFetching(false)
    onUpdate()
  }

  const setPacked = async (packed: boolean) => {
    setIsFetching(true)
    try {
      await core?.services.order.updateOrderItemPart({ id: orderItem.id, packed })
    } catch (errors) {
      alert(`Chyba:\n${JSON.stringify(errors)}`)
    }
    setIsFetching(false)
    onUpdate()
  }

  const getReturnedForProduct = (productId?: number): { count: number; returns: any[] } => {
    // console.log('returnedFor', {productId, returnedMoves})
    if (!productId) {
      return { count: 0, returns: [] }
    }
    let count = 0
    const returns =
      returnedMoves?.length > 0 ? returnedMoves.filter((pm: ProductMove) => pm.product?.id === productId) : []
    if (returns.length > 0) {
      count = returns.map((pm: ProductMove) => pm.amount).reduce((a: number, b: number) => a + b)
    }
    return { count, returns }
  }

  const getMovesToReturnForOrderItem = (orderItemId: number, productId?: number): { count: number; returns: any[] } => {
    // console.log('getMovesToReturnForOrderItem', { productId, orderItemId, movesToReturn })
    if (!productId) {
      return { count: 0, returns: [] }
    }
    let count = 0
    // console.log({ movesToReturn, orderItemId, productId })
    const returns: any[] = movesToReturn?.filter(m => m.orderItemId === orderItemId && m.productId === productId) || []
    // movesToReturn?.length > 0 ? movesToReturn.filter((pm: ProductMove) => pm.product?.id === productId) : []
    if (returns.length > 0) {
      count = returns.map(m => m.amount).reduce((a: number, b: number) => a + b)
    }
    return { count, returns }
  }
  // console.log(orderItem.batch, orderItem.expirationDate)

  const moveToPackage = async (packId: number) => {
    console.log({ packId, packages })
    const items = packages.find(p => p.id === packId).items || []
    items.push(orderItem)
    const res = await core?.services.pack.update({
      id: packId,
      carrier: currentOrder?.carrier,
      order: currentOrder,
      items,
    })
    onUpdate()
  }

  const packIndex = packages.indexOf(currentPackage) + 1

  return (
    <>
      <div
        className={`grid ${isInPackage && processStatus === 'packing' ? 'grid-cols-13' : 'grid-cols-12'} leading-10 ${
          orderItem.quantityUnavailable > 0 && 'bg-red-200'
        }`}>
        {/*1. sloupec*/}
        <div className="grid grid-cols-6 col-span-5 gap-2">
          <div className="col-end-1 self-center flex items-center pl-20">
            {orderItem.quantityUnavailable > 0 && (
              <div className={'self-center mr-2'}>
                <span className={'label-red'} title={'Položky nejsou skladem'}>
                  <span className={'font-extrabold'}>{`${orderItem.quantityUnavailable}`}</span>
                </span>
              </div>
            )}
            {isBundleChild && (
              <div className="grid grid-cols-1 pl-2 gap-2">
                <div className="col-end-1 self-center items-center">
                  <FontAwesomeIcon className={'text-primary-blue'} icon={['far', 'long-arrow-right']} size={'lg'} />
                </div>
              </div>
            )}
            <PhotoSwipe
              alt={''}
              caption={`
                        ${orderItem ? orderItem.name : ''} <br> 
                        ${orderItem?.code ? 'Kód: ' + orderItem.code : ''} <br> 
                        ${orderItem?.ean ? 'EAN: ' + orderItem.ean : ''}`}
              galleryImg={imageUrl}
              src={thumbUrl}
              thumbnails={''}
            />
          </div>
          {/*todo test with all views:*/}
          {/*<div className="col-span-6 self-center font-semibold">{orderItem?.name}</div>*/}
          <div className="col-span-5 self-center font-semibold">
            {orderItem?.name}
            <FontAwesomeIcon
              icon={['far', 'file']}
              size="lg"
              className={'ml-2 link'}
              onClick={() => window.open(`https://app.depoto.cz/product/${orderItem.product?.id}/files`)}
            />
          </div>
        </div>
        {/*2. sloupec */}
        <div className="grid grid-cols-2 col-span-2">
          {orderItem.product?.isFragile ? (
            <div className={'self-center'}>
              <span className={'label-yellow'}>
                <FontAwesomeIcon icon={['far', 'fragile']} size="lg" className={'mr-2'} />
                <span>Křehké</span>
              </span>
            </div>
          ) : (
            <span>&nbsp;</span>
          )}
          {orderItem.product?.isOversize ? (
            <div className={'self-center self-center'}>
              <span className={'label-purple'}>
                <FontAwesomeIcon icon={['far', 'expand-alt']} size="lg" className={'mr-2'} />
                <span>Nadměrné</span>
              </span>
            </div>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        {/*3. sloupec*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{orderItem.code}</div>
        {/*4. sloupec*/}
        <div className={'self-center whitespace-nowrap overflow-x-auto mr-4'}>{orderItem.ean}</div>
        {/*5. sloupec*/}
        <div className="place-self-center self-center">
          <div className="square">
            {orderItem?.product?.bundleChildren?.length! > 0 ? (
              <span className="square-amount">+</span>
            ) : (
              <span className="square-amount">{orderItem.quantity}</span>
            )}
          </div>
        </div>
        {/*6. sloupec*/}
        <div className="grid grid-cols-2">
          <div className={'col-start-1 col-end-3 place-self-center self-center p-1'}>
            {!isBundleChild && !isReturns && <EditOrderItemModal orderItem={orderItem} onUpdate={onUpdate} />}
          </div>
          {/*{!isReturns ? (*/}
          {/*  <div className={'place-self-center self-center p-1'}>*/}
          {/*    <EditExpeditionBatchPositionModal clearanceItem={clearanceItem} onUpdate={onUpdate} />*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <div className={'place-self-center self-center p-1'}>*/}
          {/*    &nbsp;*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
        {/*7. sloupec only on packing && inPackage*/}
        {isInPackage && processStatus === 'packing' && currentPackage && (
          <div className="grid grid-cols-1">
            <div className={'place-self-center self-center'}>
              <Dropdown
                // title={'Balík'}
                title={`${packIndex || ''}`}
                hasAllOption={false}
                selectedId={currentPackage?.id}
                options={packages.map((p, i) => ({ id: p.id, name: i + 1 }))}
                onChange={val => moveToPackage(val)}
              />
            </div>
          </div>
        )}
        {/*7. sloupec*/}
        {/*TODO checkboxy dopryc z orderItems view*/}
        {!isBundleChild && (
          <div className="grid grid-cols-2">
            {processStatus === 'picking' && (
              <div className={'col-start-1 col-end-3 place-self-center self-center'}>
                <Checkbox
                  checked={orderItem.picked}
                  onChange={() => setPicked(!orderItem.picked)}
                  disabled={isFetching}
                />
              </div>
            )}
            {processStatus === 'packing' && (
              <div className={'col-start-1 col-end-3 place-self-center self-center'}>
                <Checkbox
                  checked={orderItem.packed}
                  onChange={() => setPacked(!orderItem.packed)}
                  disabled={isFetching}
                />
              </div>
            )}
            {/*TODO !!!!!!!!!!!*/}
            {/*{isReturns && (*/}
            {/*  <div className={'place-self-center self-center'}>*/}
            {/*    <FormInput type="number" cssClass="w-20" value={`${amountForReturn}`} max={orderItem.quantity} onChange={val => {*/}
            {/*      const num = isNaN(Number(val)) ? 0 : Number(val)*/}
            {/*      setAmountForReturn(Number(num))*/}
            {/*      onUpdate(orderItem.id, Number(num))*/}
            {/*    }} />*/}
            {/*  </div>*/}
            {/*)}*/}
            {isReturns && (
              <div className={'place-self-center self-center'}>
                {/*{!orderItem.product?.isBundle ? (*/}
                {/*  <EditReturnItemModal*/}
                {/*    orderItem={orderItem}*/}
                {/*    onReturn={onReturn}*/}
                {/*    isDisabled={getReturnedForProduct(orderItem.product?.id).count >= orderItem.quantity}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  <div>VIRTUAL</div>*/}
                {/*)}*/}
                {/*TODO!!*/}
                WRONG COMPONENT!
              </div>
            )}
          </div>
        )}
      </div>
      {getMovesToReturnForOrderItem(orderItem.id, orderItem.product?.id).returns.length > 0 && (
        <div className="flex flex-row justify-between pl-10 pr-10">
          <div className="w-full">
            {getMovesToReturnForOrderItem(orderItem.id, orderItem.product?.id).returns.map(move => (
              <div key={`${move.orderItemId}-${move.productId}mmm`} className="w-full flex flex-row bg-red-200">
                <div className="w-1/4 text-left pl-5">Batch: {move.batch}</div>
                <div className="w-1/4 text-left">
                  ExpirationDate:&nbsp;
                  <DateFormatted date={move.expirationDate} />
                </div>
                <div className="w-1/4 text-left">
                  Position: {move.position1}-{move.position2}-{move.position3}
                </div>
                <div className="w-1/4 text-right pr-5">To return: {move.amount}ks</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {getReturnedForProduct(orderItem.product?.id).returns.length > 0 && (
        <div className="flex flex-row justify-between pl-10 pr-10">
          <div className="w-full">
            {getReturnedForProduct(orderItem.product?.id)
              .returns // .filter((pm: ProductMove) => pm.orderItem?.id === orderItem.id && pm.orderItem.product?.id === orderItem.product?.id)
              .map((pm: ProductMove) => (
                <div key={pm.id} className="w-full flex flex-row bg-cloud-blue">
                  <div className="w-1/4 text-left pl-5">Batch: {pm.batch}</div>
                  <div className="w-1/4 text-left">
                    ExpirationDate:&nbsp;
                    <DateFormatted date={pm.expirationDate} />
                  </div>
                  <div className="w-1/4 text-left">Position: {pm.position}</div>
                  <div className="w-1/4 text-right pr-5">Returned: {pm.amount}ks</div>
                </div>
              ))}
          </div>
        </div>
      )}
      {orderItem.note?.length > 0 && (
        <div className="flex flex-row justify-between pl-10 pr-10">
          <div className="w-full text-red-600">{orderItem.note}</div>
        </div>
      )}
      {orderItem?.product?.bundleChildren?.length! > 0 &&
        orderItem?.product?.bundleChildren.map((bc: ProductBundle) => (
          <OrderItemRow
            key={`${currentOrder?.id}-${orderItem.id}-${bc.id}`}
            orderItem={
              new OrderItem({
                id: orderItem.id,
                name: bc.child?.name,
                product: bc.child,
                code: bc.child?.code,
                ean: bc.child?.ean,
                // quantity: isReturns ? bc.quantity * orderItem.quantity : bc.quantity,
                quantity: bc.quantity * orderItem.quantity,
              })
            }
            onUpdate={onUpdate}
            onReturn={onReturn}
            movesToReturn={movesToReturn}
            isBundleChild={!isReturns}
            isReturns={isReturns}
            returnedMoves={returnedMoves}
            isInPackage={isInPackage}
          />
        ))}
    </>
  )
}
