import React, { useState, useEffect } from 'react'
import { FormInput } from './FormInput'
import { Btn } from './Btn'
import { wait, KeyEventType } from '../lib'
import { Order } from 'depoto-core/dist/src/entities'
import { Fn } from 'depoto-core/dist/src/models'
import { ClearanceItems } from './ClearanceItems'
import { OrderItemsForReturns } from './OrderItemsForReturns'
import { OrderItems } from './OrderItems'
import { OrderItemsInPackages } from './OrderItemsInPackages'
import { useCore } from '../hooks'

type Props = {
  order: Order
  onUpdate: Fn
}
export const OrderProducts: React.FC<Props> = ({ order, onUpdate }) => {
  const [isFetchingOrder, setIsFetchingOrder] = useState<boolean>(false)
  const [openTab, setOpenTab] = useState<number>(1)
  const [canBeReturned, setCanBeReturned] = useState<boolean>(false)
  const [box, setBox] = useState<string>('')
  const { core, currentOrder, isFetching, setIsFetching } = useCore()

  useEffect(() => {
    // console.log({ currentOrder, order })
    const processCode = async (c: CustomEvent | any) => {
      const clearanceItems = order?.clearanceItems?.filter(
        ci => ci.product?.code === c?.detail || ci.product?.ean === c?.detail,
      )
      const clearanceItem = clearanceItems?.length > 0 ? clearanceItems[0] : null
      if (clearanceItem) {
        const updates: any = { id: clearanceItem.id }
        if (order.processStatus?.id === 'picking') {
          const nextPicked = clearanceItem.picked === clearanceItem.amount ? 0 : clearanceItem.amount
          if (nextPicked > 1 && !confirm(`Máte všechny kusy (${nextPicked}) ?`)) {
            return
          }
          setIsFetchingOrder(true)
          // patchState({ isFetching: true })
          updates.picked = nextPicked
          console.warn('picking', { clearanceItem, nextPicked, updates, currentOrder })
          await core?.services.order.updateClearanceItemFromOrder(updates, order, 'picked')
          onUpdate()
        }
        if (order.processStatus?.id === 'packing') {
          const nextPacked = clearanceItem.packed === clearanceItem.amount ? 0 : clearanceItem.amount
          if (nextPacked > 1 && !confirm(`Máte všechny kusy (${nextPacked}) ?`)) {
            return
          }
          setIsFetchingOrder(true)
          // patchState({ isFetching: true })
          updates.packed = nextPacked
          console.warn('packing', { clearanceItem, nextPacked, updates, currentOrder })
          await core?.services.order.updateClearanceItemFromOrder(updates, order, 'packed')
          onUpdate()
        }
      }
      await wait(600)
      setIsFetchingOrder(false)
    }

    if (order) {
      const canReturnOrder =
        order.processStatus?.id === 'packed' ||
        order.processStatus?.id === 'dispatched' ||
        order.processStatus?.id === 'delivered' ||
        order.processStatus?.id === 'returned'
      setCanBeReturned(canReturnOrder)
      if (canReturnOrder) {
        if (order.processStatus?.id === 'returned') {
          setOpenTab(3)
        } else {
          setOpenTab(2)
        }
      }
    }

    document.addEventListener(KeyEventType.BARCODE, processCode)
    if (order.processStatus?.id === 'packing' || order.processStatus?.id === 'packed') {
      setOpenTab(2)
    }
    return () => {
      document.removeEventListener(KeyEventType.BARCODE, processCode)
    }
  }, [order, currentOrder])

  const toggleAllItems = async () => {
    setIsFetching(true)
    for (const orderItem of order.items) {
      try {
        if (order.processStatus?.id === 'picking') {
          await core?.services.order.updateOrderItemPart({ id: orderItem.id, picked: true })
        } else if (order.processStatus?.id === 'packing') {
          await core?.services.order.updateOrderItemPart({ id: orderItem.id, packed: true })
        }
      } catch (errors) {
        alert(`Chyba:\n${JSON.stringify(errors)}`)
      }
    }
    // hotfix:
    for (const clearanceItem of order.clearanceItems) {
      try {
        if (order.processStatus?.id === 'picking') {
          await core?.services.order.updateClearanceItemFromOrder(
            { id: clearanceItem.id, picked: clearanceItem.amount },
            order,
            'picked',
          )
        } else if (order.processStatus?.id === 'packing') {
          await core?.services.order.updateClearanceItemFromOrder(
            { id: clearanceItem.id, packed: clearanceItem.amount },
            order,
            'packed',
          )
        }
      } catch (errors) {
        alert(`Chyba:\n${JSON.stringify(errors)}`)
      }
    }
    setIsFetching(false)
    onUpdate()
  }

  const addBox = async () => {
    if (box?.length > 0) {
      setIsFetching(true)
      await core?.services.order.updateOrderPart({ id: order.id, boxes: [...order.boxes, box] })
      setIsFetching(false)
      setBox('')
      onUpdate()
    }
  }

  return (
    <div>
      <ul className="p-sub-main flex justify-between border-b border-cool-grey" role="tablist">
        <div className={'flex gap-4'}>
          <div className={'my-auto'}>
            <div className={'flex bg-cool-grey border border-cool-grey rounded-md gap-px'}>
              {!canBeReturned && (
                <li className="text-center my-auto">
                  <a
                    className={
                      'text-sm font-bold px-5 py-3 rounded block ' +
                      (openTab === 1 ? 'bg-primary-blue text-white' : 'bg-white text-black')
                    }
                    onClick={e => {
                      e.preventDefault()
                      setOpenTab(1)
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist">
                    Položky k expedici
                  </a>
                </li>
              )}
              <li className="text-center my-auto">
                <a
                  className={
                    'text-sm font-semibold px-5 py-3 rounded block ' +
                    (openTab === 2 ? 'bg-primary-blue text-white' : 'bg-white text-black')
                  }
                  onClick={e => {
                    e.preventDefault()
                    setOpenTab(2)
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist">
                  Položky objednávky
                </a>
              </li>
              {canBeReturned && (
                <li className="text-center my-auto">
                  <a
                    className={
                      'text-sm font-semibold px-5 py-3 rounded block ' +
                      (openTab === 3 ? 'bg-primary-blue text-white' : 'bg-white text-black')
                    }
                    onClick={e => {
                      e.preventDefault()
                      setOpenTab(3)
                    }}
                    data-toggle="tab"
                    href="#link3"
                    role="tablist">
                    Vratky
                  </a>
                </li>
              )}
            </div>
          </div>
          {(order.processStatus?.id === 'picking' || order.processStatus?.id === 'packing') && (
            <li className={'flex gap-2 items-center'}>
              <FormInput
                label={''}
                isDisabled={false}
                placeholder={'Přepravka'}
                type={'text'}
                value={box}
                onChange={val => setBox(val)}
                onSubmit={addBox}
              />
              {order.boxes.join(', ')}
              <Btn
                cssClass="btn-secondary"
                icon={'fa-download'}
                children={'Přidat přepravku'}
                title={'Přidat přepravku'}
                isLoading={isFetching || isFetchingOrder}
                onClick={addBox}
              />
            </li>
          )}
        </div>
        {(order.processStatus?.id === 'picking' || order.processStatus?.id === 'packing') && (
          <Btn
            cssClass="btn-outline border-light-grey"
            icon={'check-square'}
            children={'Označit vše'}
            title={'Označit vše'}
            isLoading={isFetching || isFetchingOrder}
            onClick={toggleAllItems}
          />
        )}
      </ul>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full rounded scrollable-content">
        <div>
          <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
            <ClearanceItems order={order} onUpdate={onUpdate} />
          </div>
          <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
            {order.processStatus?.id === 'received' ||
            order.processStatus?.id === 'recieved' ||
            order.processStatus?.id === 'picking' ? (
              <OrderItems order={order} onUpdate={onUpdate} />
            ) : (
              <OrderItemsInPackages order={order} onUpdate={onUpdate} />
            )}
          </div>
          <div className={openTab === 3 ? 'block' : 'hidden'} id="link3">
            {openTab === 3 && <OrderItemsForReturns order={order} onUpdate={onUpdate} />}
          </div>
        </div>
      </div>
    </div>
  )
}
