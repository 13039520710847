import React, { useState, useEffect } from 'react'
import { Main } from './Main'
import { FormSelect } from '../components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { handleBarcodeScanner, unregisterBarcodeScanner, testBarcodeScanner, barcodeTestReadTimes } from '../lib'
import { useCore } from '../hooks'
import { version } from '../../package.json'

type Props = {}
export const Settings: React.FC<Props> = ({}) => {
  const [printers, setPrinters] = useState<string[]>([])
  const [printer, setPrinter] = useState<string | null>(window.localStorage.getItem('printer'))
  const [printer2, setPrinter2] = useState<string | null>(window.localStorage.getItem('printer2'))
  const [printer2byBrowser, setPrinter2byBrowser] = useState<boolean>(
    !!window.localStorage.getItem('printer2byBrowser'),
  )
  const [isSettingScanner, setIsSettingScanner] = useState<boolean>(false)
  const [scannerResult, setScannerResult] = useState<number[]>([])
  const [scannerTts, setScannerTts] = useState<number>(300)
  const { core, isStrictDepotFilter, setIsStrictDepotFilter, autoProcessStatusChange, setAutoProcessStatusChange } =
    useCore()
  const desktopApp = window._depoto
  const clientEmail = core?.services.oauth.session.email

  useEffect(() => {
    const stts = window.localStorage.getItem('scanner-tts')
    setScannerTts(Number(stts) || 300)
  }, [])

  useEffect(() => {
    if (desktopApp) {
      setTimeout(() => loadPrinterList(), 500)
      loadPrinterList()
    }
    return () => {
      if (isSettingScanner) {
        toggleScannerListener()
      }
    }
  }, [isSettingScanner])

  useEffect(() => {
    if (scannerResult.length > 0 && isSettingScanner) {
      toggleScannerListener()
    }
  }, [scannerResult, isSettingScanner])

  const selectPrinter = (printerName: string) => {
    setPrinter(printerName)
    if (printerName === '') {
      window.localStorage.removeItem('printer')
    } else {
      window.localStorage.setItem('printer', printerName)
    }
  }

  const selectPrinter2 = (printerName: string) => {
    setPrinter2(printerName)
    if (printerName === '') {
      window.localStorage.removeItem('printer2')
    } else {
      window.localStorage.setItem('printer2', printerName)
    }
  }

  const selectPrinter2ByBrowser = (isChecked: boolean) => {
    setPrinter2byBrowser(isChecked)
    if (!isChecked) {
      window.localStorage.removeItem('printer2byBrowser')
    } else {
      window.localStorage.setItem('printer2byBrowser', 'true')
    }
  }

  const clearCache = () => {
    desktopApp?.nw?.clearCache()
    alert('Cache smazána, restartujte aplikaci')
    setTimeout(() => desktopApp?.nw?.nw?.App?.quit(), 500)
  }

  const loadPrinterListFromNw = async (): Promise<string[]> => {
    return new Promise(resolve => {
      desktopApp?.nw?.win?.getPrinters((p: any[]) => resolve(p.map(d => d.deviceName)))
    })
  }

  const loadPrinterList = async () => {
    let pList: string[] = await desktopApp?.node?.getPrinters() // does not work on ubuntu 18 for some reason
    // console.warn('printers lp: ', pList)
    if (!pList?.length) {
      pList = await loadPrinterListFromNw()
      // console.warn('printers nw: ', pList)
    }
    pList.unshift('')
    setPrinters(pList)
  }

  const toggleScannerListener = () => {
    if (!isSettingScanner) {
      setScannerResult([])
      unregisterBarcodeScanner(core!)
      barcodeTestReadTimes.onComplete = res => {
        if (res.length > 60) {
          const ttsAvg = res.reduce((a, b) => a + b) / res.length
          const ttsMax = Math.max(...res)
          const tts = 5 * ttsAvg < ttsMax ? 5 * ttsAvg : ttsMax - ttsAvg
          console.log('barcodeTestReadTimes.onComplete', { res, ttsMax, ttsAvg, tts, ttwbs: tts * 50 })
          window.localStorage.setItem('scanner-tts', `${Math.floor(tts * 50)}`)
          setScannerTts(Math.floor(tts * 50))
          core?.services.keyEvent?.setTimeToScan(Math.floor(tts * 50))
          barcodeTestReadTimes.result = []
          setScannerResult(res)
        }
      }
      window.addEventListener('keydown', testBarcodeScanner)
    } else {
      window.removeEventListener('keydown', testBarcodeScanner)
      core?.services.keyEvent?.startListeners()
      handleBarcodeScanner(core!)
    }
    setIsSettingScanner(!isSettingScanner)
  }

  const resetScannerTts = () => {
    window.localStorage.setItem('scanner-tts', `${300}`)
    setScannerTts(300)
  }

  return (
    <div className={'p-main grid grid-cols-2 gap-4'}>
      <div className={'flex gap-2 items-center'}>
        <div
          className={'h-12 w-12 flex justify-center items-center rounded-full bg-base-blue cursor-pointer'}
          onClick={() => window.open('https://app.depoto.cz/my/profile', '_blank')}>
          <div>
            <FontAwesomeIcon icon={['far', 'user']} className={'text-primary-blue'} size={'lg'} />
          </div>
        </div>
        <div
          className={'text-primary-blue font-semibold cursor-pointer'}
          onClick={() => window.open('https://app.depoto.cz/my/profile', '_blank')}>
          {clientEmail}
        </div>
      </div>
      <div className={'flex gap-2 items-center justify-end cursor-pointer'}>
        <div
          className={'font-semibold text-right'}
          onClick={() => window.open('https://gitlab.tomatom.cz/depoto/expedice-v2/-/tags', '_blank')}>
          v{version}
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2">
          <label htmlFor="strictDepots">Pouze objednávky vyskladnitelné z vybraného skladu</label>
          <input
            id={'strictDepots'}
            type={'checkbox'}
            checked={isStrictDepotFilter}
            onChange={() => {
              window.localStorage.setItem('isStrictDepotFilter', `${!isStrictDepotFilter}`)
              setTimeout(() => setIsStrictDepotFilter(!isStrictDepotFilter), 100)
            }}
          />
        </div>
        <div className="flex items-center gap-2">
          <label htmlFor="autoProcessStatusChange">Posunout stav automaticky</label>
          <input
            id={'autoProcessStatusChange'}
            type={'checkbox'}
            checked={autoProcessStatusChange}
            onChange={() => {
              window.localStorage.setItem('autoProcessStatusChange', `${!autoProcessStatusChange}`)
              setTimeout(() => setAutoProcessStatusChange(!autoProcessStatusChange), 100)
            }}
          />
        </div>
        <div className="mt-12">
          {!window._depoto && (
            <div>
              <a href="https://depoto.cz/apps" className="font-semibold">
                Stažení Depoto desktop aplikace
              </a>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2 mt-1 mb-2">
          <label>Nastavení čtečky čárových kódů {scannerTts > 300 ? `${scannerTts}ms` : '(default)'}</label>
        </div>
        <div>
          {/*unfocus hack..*/}
          {!isSettingScanner && (
            <>
              <button className="btn btn-outline mb-2" onClick={toggleScannerListener}>
                Spustit průvodce nastavením
              </button>
              {scannerTts > 300 && (
                <button className="btn btn-outline mb-2 ml-2" onClick={resetScannerTts}>
                  Resetovat nastavení čtečky
                </button>
              )}
            </>
          )}
          {isSettingScanner && (
            <button className="btn btn-outline mb-2" onClick={toggleScannerListener}>
              Ukončit nastavení scanneru
            </button>
          )}
          {isSettingScanner && (
            <div className="alert bg-primary text-white">
              Prosím opakovaně načtěte čárový kód (optimálně {'>'} 10 znaků){' '}
            </div>
          )}
          {scannerResult.length > 0 && (
            <div className="alert bg-primary text-white">Čtečka čárových kódů je nastavena!</div>
          )}
        </div>
      </div>
      {!!desktopApp && (
        <div onClick={loadPrinterList}>
          <FormSelect
            options={printers.map(p => ({ label: p, value: p }))}
            value={printer || ''}
            onChange={selectPrinter}
            label={'Výběr tisku štítky'}
            cssClass={'w-64'}
          />
          <FormSelect
            options={printers.map(p => ({ label: p, value: p }))}
            value={printer2 || ''}
            onChange={selectPrinter2}
            label={'Výběr tisku A4'}
            cssClass={'w-64'}
          />
          <div className="flex items-center gap-2">
            <label htmlFor="print2byBrowser">Tisk A4 pomocí browser dialogu</label>
            <input
              id={'print2byBrowser'}
              type={'checkbox'}
              checked={printer2byBrowser}
              onChange={() => selectPrinter2ByBrowser(!printer2byBrowser)}
            />
          </div>
          <div>
            <div className="btn btn-primary mt-10" onClick={() => clearCache()}>
              Smazat cache
            </div>
          </div>
          <div>
            <div className="btn btn-primary mt-10" onClick={() => window._depoto?.setUdevRules()}>
              Nastavit usb pravidla pro Dymo váhy
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
