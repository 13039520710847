import React, { useEffect } from 'react'
import { DepotoCore } from 'depoto-core'
import { name as appName } from '../package.json'
import './App.css'
import { handleBarcodeScanner, Router, handleScale, strings } from './lib'
import { NotificationEvent } from 'depoto-core/dist/src/models'
import { StoreProvider } from './store'
import { EVENT_SHOW_NOTIFICATION } from './components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

declare global {
  interface Window {
    _depoto: any
    depotoEnv?: 'prod' | 'stage' | 'dev' | 'custom'
    lastRoute: string
    batchGroupStatus: { [groupId: string]: { status: any; errors: any } }
    weightHistory: string[]
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
    },
  },
})

const core: DepotoCore = new DepotoCore(appName)
// const core: DepotoCore = new DepotoCore(appName, new OAuthSession({
//   clientId: '26_24166lmf1t0k00gogck8gsgc88og0848g88owwoc4w0404w4og',
//   clientSecret: '1ccidhta12pwk84ocow0wg00osksgk8oow8kksssc8gsc4og0k',
// }))

const App = () => {
  useEffect(() => {
    document.title = strings.appName
    handleBarcodeScanner(core)
    handleScale()
    window.lastRoute = window.location.pathname !== '/login' ? window.location.pathname : '/'
    core.services.baseEvent.subscribe().then((notificationEvent: NotificationEvent) => {
      if (
        notificationEvent.operation === 'update' &&
        notificationEvent.method === 'updateOrder' &&
        notificationEvent.type === 'resolve'
      ) {
        if (notificationEvent.errors.length > 0) {
          document.dispatchEvent(
            new CustomEvent(EVENT_SHOW_NOTIFICATION, {
              detail: {
                message: notificationEvent.errors.join(', '),
                type: 'warning',
              },
            }),
          )
        } else {
          // const orderId = notificationEvent?.response?.data?.updateOrder?.data?.id // todo reservationNumber
          const orderId = ''
          document.dispatchEvent(
            new CustomEvent(EVENT_SHOW_NOTIFICATION, {
              detail: {
                message: `Objednávka ${orderId} uložena`,
                type: 'info',
              },
            }),
          )
        }
      }
    })
    core.services.baseEvent.subscribeToErrors().then(e => {
      console.log({ e })
      const errors = (e as (string | { message: string })[])
        .filter(err => typeof err === 'string' || typeof err.message === 'string')
        .map(err => (typeof err === 'string' ? err : err.message || 'Unknown error'))

      if (Array.isArray(errors) && errors.length > 0) {
        if (!errors[0]?.includes('Anonym')) {
          document.dispatchEvent(
            new CustomEvent(EVENT_SHOW_NOTIFICATION, {
              detail: {
                message: errors.join(', '),
                type: 'warning',
              },
            }),
          )
        }
      } else if (e?.status === 500) {
        document.dispatchEvent(
          new CustomEvent(EVENT_SHOW_NOTIFICATION, {
            detail: {
              message: 'Chyba: error 500',
              type: 'warning',
            },
          }),
        )
      }
    })
  }, [])

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <Router core={core} />
        </StoreProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
